<template>
  <div class="card gutter-b card-stretch">
    <!-- <div class="card-header h-auto border-0">
      <div class="card-title">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder"
            >Weblog</span
          >
          <span class="d-block text-muted mt-2 font-size-sm"
            >More than 500+ new orders</span
          >
        </h3>
      </div>
    </div> -->
    <!-- <div class="card-body d-flex flex-column p-0"> -->
      
    <div class="table-responsive">
      <!-- Start of loading animation -->
      <div class="p-10" v-if="loading">
      <!-- <div class="card-body"> -->
      <div class="spinner spinner-primary spinner-md spinner-center"></div>
      <p class="text-6xl font-bold text-center text-gray-500 animate-pulse mt-5">
        Loading...
      </p>
      <!-- </div> -->
      <!-- End of loading animation -->
      <!-- Start of error alert -->
      <v-alert border="top" color="red lighten-2" v-if="error.length > 0" dark>
        {{ error.response }}
      </v-alert>
      </div>

      <vue-good-table  v-if="!loading"
        :columns="columns"
        :rows="data"
        styleClass="vgt-table /*striped*/"
        :fixed-header="false"
        compactMode
        :pagination-options="{
          enabled: true,
          perPage: 50,
          mode: 'pages'
        }"  
        :search-options="{
          enabled: false,
          placeholder: 'Search for a customer',
        }"
          @on-row-click="onRowClick">
        >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'webshopUrl'">
            <span class="svg-icon"><i class="flaticon2-send-1 text-success"></i></span>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>    
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';


export default {
  // name: "line-base",
  components: {
    // MixedWidget1,
    VueGoodTable,
  },  
  data() {
    return {
      loading: true,
      error: [],
      data: [],
      columns: [
        {
          label: 'Debnr',
          field: 'debnr',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-hover-primary font-size-lg',
          width: '100px',
          filterOptions: {
          // styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter', // placeholder for filter input
            // filterValue: 'Jane', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, //custom filter function that
            // trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Name',
          field: 'debnaam',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder text-hover-primary font-size-lg',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'City',
          field: 'city',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder text-hover-primary font-size-lg',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Zipcode',
          field: 'zipcode',
          thClass: 'text-muted font-size-sm',
          tdClass: 'text-dark-75 font-weight-bolder text-hover-primary font-size-lg',
          filterOptions: {
            enabled: true, // enable filter for this column
          },
        },
        {
          label: 'Shop',
          field: 'webshopUrl',
          // type: 'text',
          thClass: 'text-muted font-size-sm text-right',
          tdClass: 'text-muted font-size-sm text-right',
          formatFn: this.formatNumbers,
          // type: 'percentage',
        },
      ],
      // lineId: this.$route.params.id,
    }
  },
  mounted() {
    // console.log(this.$route.params);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Home > Cash & carry Sales"}]);
    // console.log(this.lineId);
    axios
      .get("/home/ccsales")
      .then((response) => {
        this.data = response.data;
        this.loading = false;
      })
      .catch((error) => {
        this.error.response = "Error msg";
        // console.log(error.response)
        this.loading = false;
        throw error;
      });
  },
  methods: {
    formatNumbers: function(value) {
      return value.toLocaleString('nl-NL');
    },
    onRowClick(params){
      window.open(`${params.row.webshopUrl}/Account/LoginByUrl?user=${params.row.debnr}&pass=${params.row.password}&returnurl=/Voorraad/TRUCK`, '_blank');
    },
  },
};
</script>
